import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { CurrentConnectionService } from '../../../connector/services/current-connection/current-connection.service';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { AuthTokenService } from '../auth-token/auth-token.service';
import { AuthService } from '../auth/auth.service';
import { LoginFormModel } from '../../models/login-form/login-form.model';
import { ActivateWorkshopAccountFormModel } from '../../models/activate-workshop-account-form/activate-workshop-account-form.model';
import { RecipeFiltersService } from '../../../recipes/services/recipe-filters/recipe-filters.service';
import { CurrentUserPermissionsService } from '../../../user-roles/services/current-user-permissions/current-user-permissions.service';
import { CurrentAdditionalSettingsService } from '../../../settings/services/current-additional-settings/current-additional-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  constructor(
    private authService: AuthService,
    private authTokenService: AuthTokenService,
    private currentUserService: CurrentUserService,
    private currentSettingsService: CurrentSettingsService,
    private currentAdditionalSettingsService: CurrentAdditionalSettingsService,
    private currentConnectionService: CurrentConnectionService,
    private recipeFiltersService: RecipeFiltersService,
    private currentUserPermissionsService: CurrentUserPermissionsService
  ) {}

  public initCurrentUser(): Observable<void> {
    if (!this.authTokenService.isAccessToken()) {
      return of(void 0);
    }

    return this.currentUserService.refreshCurrentUser();
  }

  public activateWorkshopAccount(value: ActivateWorkshopAccountFormModel): Observable<void> {
    return this.currentUserService.activateWorkshopAccount(value);
  }

  public login(loginFormModel: LoginFormModel): Observable<void> {
    return this.authService.login(loginFormModel).pipe(
      tap((accessToken: string) => {
        this.authTokenService.setAccessToken(accessToken);
      }),
      switchMap(() => this.currentUserService.refreshCurrentUser()),
      switchMap(() => this.currentSettingsService.updateCurrentSettings()),
      switchMap(() => this.currentAdditionalSettingsService.updateCurrentAdditionalSettings()),
      switchMap(() => this.currentUserPermissionsService.initPermissions()),
      map(() => void 0)
    );
  }

  public logoutFromApiAndApplication(): Observable<void> {
    const accessToken: string | null = this.authTokenService.getAccessToken();

    if (!accessToken) {
      return this.logoutFromApplication();
    }

    return this.authService.logout().pipe(switchMap(() => this.logoutFromApplication()));
  }

  public logoutFromApplication(): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this.authTokenService.removeAccessToken();
        this.currentUserService.clearCurrentUser();
        this.currentSettingsService.clearCurrentSettings();
        this.currentAdditionalSettingsService.clearCurrentAdditionalSettings();
        this.currentConnectionService.clearStationId();
        this.currentConnectionService.stopObservingConnectorConnections();
        this.recipeFiltersService.resetDefaultValues();
      })
    );
  }

  public deleteUserProfileAndLogout(): Observable<void> {
    return this.currentUserService.deleteCurrentUser().pipe(switchMap(() => this.authService.logout()));
  }
}
