import { Injectable } from '@angular/core';

import { RecipesFiltersFormStatesModel } from '../../models/recipes-filters-form-states/recipes-filters-form-states.model';
import { RecipesFiltersModel } from '../../models/recipes-filters/recipes-filters.model';
import { RecipesFiltersEnum } from '../../enums/recipes-filters/recipes-filters.enum';
import { EffectTypesEnum } from '../../enums/effect-types/effect-types.enum';

@Injectable({
  providedIn: 'root',
})
export class RecipeFiltersService {
  public defaultColorSystem: string | null;

  constructor() {
    this.defaultColorSystem = null;
  }

  public setDefaultColorSystem(value: string): void {
    this.defaultColorSystem = value;
  }

  public resetDefaultValues(): void {
    this.defaultColorSystem = null;
  }

  public getFormStates(): RecipesFiltersFormStatesModel {
    return {
      brand: { isDisabled: false, dictionaryParentQuery: '' },
      model: { isDisabled: false, dictionaryParentQuery: '' },
      producerColorCode: { isDisabled: false, dictionaryParentQuery: '' },
      producerColor: { isDisabled: false, dictionaryParentQuery: '' },
      colorSystem: { isDisabled: false, dictionaryParentQuery: '' },
      colorBook: { isDisabled: false, dictionaryParentQuery: '' },
      flashcard: { isDisabled: false, dictionaryParentQuery: '' },
    };
  }

  public checkIfHasFilter(recipesFilters: RecipesFiltersModel): boolean {
    const keys: Array<string> = this.getFilteredFilterKeys(recipesFilters);

    if (!keys.length) {
      return false;
    }

    if (!this.defaultColorSystem && !!recipesFilters.colorSystem) {
      return true;
    }

    if (this.defaultColorSystem && this.defaultColorSystem !== recipesFilters.colorSystem) {
      return true;
    }

    delete recipesFilters['colorSystem'];

    return !!Object.keys(recipesFilters).length;
  }

  private getFilteredFilterKeys(recipesFilters: RecipesFiltersModel): Array<string> {
    return Object.keys(recipesFilters).filter((key: string) => {
      const index: keyof typeof recipesFilters = key as keyof typeof recipesFilters;

      return recipesFilters[index] && (index !== RecipesFiltersEnum.effectTypes || recipesFilters[index] !== EffectTypesEnum.automatic);
    });
  }
}
